import React from 'react';
import styled from 'styled-components';
import ExternalLink from './externalLink';
import appstore from '../images/appstore.png';
import googleplay from '../images/googleplay.png';

const Container = styled.div`
margin-top: 20px;
margin-bottom: 15px;
margin-left: 10px;
margin-right: 10px;
display: flex;
flex-wrap: wrap;
justify-content: center; 
align-items: center; 
`;

const Hover = styled.div`
  margin: 8px;
  transition: 0.15s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
`;

const ButtonsBox = () => (
  <Container>
    <Hover>
      <ExternalLink href="https://apps.apple.com/jp/app/voox-%E3%83%97%E3%83%AD%E3%83%95%E3%82%A7%E3%83%83%E3%82%B7%E3%83%A7%E3%83%8A%E3%83%AB%E3%81%8B%E3%82%89%E5%AD%A6%E3%81%B9%E3%82%8B%E9%9F%B3%E5%A3%B0%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2/id1546175303">
        <input style={{ width: 142, height: 52 }} type="image" id="image" alt="App Store" src={appstore} />
      </ExternalLink>
    </Hover>
    <Hover>
      <ExternalLink href="https://play.google.com/store/apps/details?id=me.voox">
        <input style={{ width: 174.69, height: 50.25 }} type="image" id="image" alt="Google Play Store" src={googleplay} />
      </ExternalLink>
    </Hover>
  </Container>
);

export default ButtonsBox;
