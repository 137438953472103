import React from "react"

function Back() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="19"
      fill="none"
      viewBox="0 0 10 19"
    >
      <path
        fill="#060606"
        fillRule="evenodd"
        d="M0 9.5L8.484.486l1.092 1.028L2.06 9.5l7.516 7.986-1.092 1.028L0 9.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Back
