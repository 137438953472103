import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import LinkBar from "../components/linkBar"
import TryVoox from "../components/tryVoox"
import Footer from "../components/footer"
import Modal from "../components/modal"
import { useSiteMetadataSizes } from "../hooks/useSiteMetadata"
import Back from "../svg/back"
import Helmet from "react-helmet"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 25px;
  max-width: 980px;
  padding-bottom: 200px;
`

const EpisodeContainer = styled.div`
  display: flex;
  margin: 120px 0 60px;

  @media (max-width: 840px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const ProfileImg = styled.img`
  object-fit: cover;
  width: 356px;
  height: 506px;

  -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 500ms; /* Firefox < 16 */
  -ms-animation: fadein 500ms; /* Internet Explorer */
  -o-animation: fadein 500ms; /* Opera < 12.1 */
  animation: fadein 500ms;

  @media (max-width: 840px) {
    width: 100%;
    height: 506px;
  }
`

const EpisodeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 80px;
  max-width: 464px;
  text-align: center;

  @media (max-width: 840px) {
    max-width: initial;
    margin-right: 0;
  }
`

const Author = styled.h1`
  @media (max-width: 840px) {
    margin-top: 24px;
  }
`

const SubheaderLine = styled.div`
  margin-bottom: 40px;
  height: 20px;
  width: 100%;
  max-width: 336px;
  border-bottom: 2px solid #ff0606;

  @media (max-width: 840px) {
    margin-bottom: 30px;
  }
`

const DotLine = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
  height: 20px;
  width: 100%;
  max-width: 356px;
  background-image: linear-gradient(
    to right,
    #000,
    #000 1px,
    transparent 2px,
    transparent 8px
  ); /* 幅2の線を作る */
  background-size: 8px 2px; /* グラデーションの幅・高さを指定 */
  background-position: left bottom; /* 背景の開始位置を指定 */
  background-repeat: repeat-x;

  @media (max-width: 840px) {
    margin-bottom: 30px;
  }
`

const Voox = styled.div`
  font-size: 20px;
  font-family: 'Noto Serif JP';
`

const EpisodeTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: bold;
`

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  text-align: left;
`

const BreadcrumbContainer = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
  max-width: 980px;
  font-size: 16px;
  color: #000000;

  a {
    color: #000000;
    text-decoration: none;
    opacity: 40%;
  }

  .arrow {
    opacity: 40%;
    margin: 0 6px;
  }
`

const BackContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 140px;

  a {
    color: #000000;
    text-decoration: none !important;
  }
`

const BackList = styled.div`
  display: flex;
  align-items: center;
`

const BackText = styled.span`
  margin-left: 4px;
  padding-bottom: 2px;
  font-size: 20px;
  font-weight: medium;
  border-bottom: 1px solid #000000;
  text-decoration: none;
`

export default ({ pageContext }) => {
  const {
    author,
    imageUrl,
    imageOgpUrl,
    introduction,
    description,
    id,
    title,
  } = pageContext.episode
  const sizes = useSiteMetadataSizes()
  const ogpTitle = author + " | VOOX"

  return (
    <Layout>
      <Helmet
        title={ogpTitle}
        meta={[
          { name: "description", content: description },
          { name: "og:title", content: ogpTitle },
          { name: "og:description", content: description },
          { name: "og:image", content: imageOgpUrl },
          { name: "og:url", content: "https://voox.me/episodes/" + id },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "@VOOX_jp" },
        ]}
      />
      <LinkBar />
      <Container>
        <Main>
          <BreadcrumbContainer>
            <Link to="/" replace>
              TOP
            </Link>
            <div className="arrow">{" > "}</div>
            <Link to="/episodes/" replace>
              スピーカーを見る
            </Link>
            <div className="arrow">{" > "}</div>
            {author}
          </BreadcrumbContainer>
          <EpisodeContainer>
            <EpisodeInfo>
              <Author>{author}</Author>
              <SubheaderLine />
              <Description>{introduction}</Description>
              <DotLine />
              <Voox>VOOX ORIGINALS</Voox>
              <EpisodeTitle>『{title}』</EpisodeTitle>
              <Description>{description}</Description>
            </EpisodeInfo>
            <ProfileImg src={imageUrl} />
          </EpisodeContainer>
          <BackContainer>
            <Link to="/episodes/" replace>
              <BackList>
                <Back />
                <BackText>スピーカーを見る</BackText>
              </BackList>
            </Link>
          </BackContainer>
          <TryVoox sizes={sizes} small={true} />
        </Main>
      </Container>
      <Footer sizes={sizes} />
    </Layout>
  )
}
