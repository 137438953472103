import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ButtonsBox from "../components/buttonsBox"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 600;
  font-size: ${({ small }) => (small ? "28px" : "36px")};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    font-size: 30px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    font-size: 24px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    font-size: 20px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

const Line1 = styled.div`
  margin-top: 24px;
  margin-bottom: 38px;
  border-bottom: 1px solid #000000;
  width: 300px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 240px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

const TryVoox = ({ sizes, small }) => (
  <Container>
    <Header sizes={sizes} small={small}>
      早速VOOXを聴いてみよう！
    </Header>
    <Line1 sizes={sizes} />
    <ButtonsBox />
  </Container>
)

export default TryVoox
